import {
  closestTo,
  startOfToday as dateFNSStartOfToday,
  format,
  getDaysInMonth,
  isBefore,
  isToday,
  isValid as isValidDate,
} from 'date-fns';
import {
  formatInTimeZone,
  toDate,
  utcToZonedTime,
  zonedTimeToUtc,
} from 'date-fns-tz';
import { kebabCase } from 'lodash';
import queryString from 'query-string';

// default values
const DEFAULT_TIMEZONE = 'Etc/UTC';

// affiliate constants
const DEFAULT_AMAZON_TRACKING_ID = 'bttb-io-20';
const PROGRAMMATIC_AMAZON_TRACKING_ID = 'bttb-io-pro-20';

// additional constants
const NEWSLETTER_SIGNUP_URL =
  'https://bttb.us13.list-manage.com/subscribe/post?u=a5df4185b71d91920f3d7e073&id=a2721b7794&f_id=00cedce2f0';

const isDevelopment = process.env.NODE_ENV === 'development';
const isProduction = process.env.NODE_ENV === 'production';

const AMAZON_URL_REGEX = /^(https?:\/\/)?(www\.)?amazon\.com(\/|$)/;

const isAmazonURL = (url) => Boolean(url) && AMAZON_URL_REGEX.test(url);

// make a URL absolute
const absoluteURL = (path) => {
  // if there's no path, return null
  if (!path) return null;

  // if this is already an absolute URL, return it
  if (path.startsWith('https://' || path.startsWith('http://'))) return path;

  // add the domain
  return `https://bttb.io${path.startsWith('/') ? '' : '/'}${path}`;
};

// return "are" or "is" depending on whether a string ends in s,
// or if a length is passed in
// note: when passing a string, this won't work with singular products whose name ends in s
const areIs = (value) => {
  // if a count is passed in, use it
  if (typeof value === 'number') {
    return value === 1 ? 'is' : 'are';
  }

  // else, treated it as text
  return value.endsWith('s') ? 'are' : 'is';
};

// SSR helpers
const isBrowser = typeof window !== 'undefined';
const isSSR = !isBrowser;

// logging
const log = (message) => {
  if (
    isBrowser &&
    (isDevelopment || window.location.search.includes('debug=true'))
  ) {
    // eslint-disable-next-line no-console
    console.log(message);
  }
};

// cookies
const USP_COOKIE_NAME = 'bttb-usp';
const getCookie = (key) => {
  const match =
    typeof document !== 'undefined' &&
    document.cookie.match(new RegExp(`(?:^|;)\\s*${key}=([^;]*)(?:;|$)`));

  return (match instanceof Array && match[1]) || '';
};

const setCookie = (
  key,
  value,
  { expires, path, domain, secure, sameSite } = {},
) => {
  let cookie = `${key}=${value}`;

  if (expires) {
    cookie +=
      expires instanceof Date
        ? `; expires=${expires.toUTCString()}`
        : `; max-age=${expires.toString()}`;
  }

  if (path) {
    cookie += `; path=${path}`;
  }

  if (domain) {
    cookie += `; domain=${domain}`;
  }

  if (secure) {
    cookie += '; secure';
  }

  if (sameSite) {
    cookie += `; SameSite=${sameSite}`;
  }

  if (typeof document !== 'undefined') {
    document.cookie = cookie;
  }
};

// dates
const NOW = new Date();
const CURRENT_YEAR = new Date().getFullYear();
const NEXT_YEAR = CURRENT_YEAR + 1;

// returns the number of days in the month
const daysInMonth = (year, monthNumber) =>
  getDaysInMonth(new Date(year, monthNumber - 1));

// convert a month number to two digits
const convertToTwoDigits = (number) =>
  `${number}`.length === 1 ? `0${number}` : `${number}`;

// get the month name by number
const monthName = (monthNumber) =>
  format(new Date(CURRENT_YEAR, monthNumber - 1, 1), 'MMMM');

// extract a flat list of dates from the bttbDates object
const extractDates = (bttbDates) => bttbDates.flatMap(({ dates }) => dates);

// extract a flat list of months from the bttbDates object
const extractMonths = (bttbDates) => bttbDates.flatMap(({ months }) => months);

// get the "next best date" from a list of dates
const getNextBestDate = (dates = []) => {
  // build a list containing each individual day
  // each date in dates is in UTC
  const dateList = extractDates(dates).map((date) => {
    // `utcToZonedTime` ensures the same day is used but then changes the time to the user's timezone
    // `toDate` is used as a timezoned-alternative to `parseISO`
    return utcToZonedTime(toDate(date, DEFAULT_TIMEZONE), DEFAULT_TIMEZONE);
  });

  // compare to today at midnight; exclude past dates
  // prefer current or future date, else return the closest past date instead
  const startOfToday = dateFNSStartOfToday();
  return (
    closestTo(
      startOfToday,
      dateList.filter((date) => !isBefore(date, startOfToday)),
    ) || closestTo(startOfToday, dateList)
  );
};

// is this a touch device?
const isTouch =
  isBrowser &&
  ('ontouchstart' in window ||
    window.navigator.maxTouchPoints > 0 ||
    window.navigator.msMaxTouchPoints > 0);

// monetize affiliate links
const monetizeAffiliateLink = (url) => {
  // if this is an Amazon link, ensure it has tracking information
  if (isAmazonURL(url)) {
    // parse the Amazon URL
    const parsedURL = queryString.parseUrl(url);

    // if Amazon tracking ID is not known, add a known one
    parsedURL.query.tag = [
      DEFAULT_AMAZON_TRACKING_ID,
      PROGRAMMATIC_AMAZON_TRACKING_ID,
    ].includes(parsedURL.query.tag)
      ? parsedURL.query.tag
      : DEFAULT_AMAZON_TRACKING_ID;

    return {
      href: queryString.stringifyUrl(parsedURL),
      isMonetized: true,
    };
  }

  // monetize non-Amazon links via Skimlinks
  return {
    href: `https://go.skimresources.com/?id=227967X1710232&url=${encodeURIComponent(
      url,
    )}`,
    isMonetized: true,
  };
};

// check if an email address is valid
const isValidEmail = (email) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi; // eslint-disable-line no-useless-escape
  return regex.test(email);
};

const logGTMEvent = (event, options = {}) => {
  const eventData = { event, ...options };

  log(`Logging event to GTM: ${JSON.stringify(eventData)}`);

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error('Error logging to GTM', err, eventData);
  }
};

// extract a single product's BTTB reason for a month
const extractBTTBReasonFromMonth = (month, productIdentifier) => {
  const reason =
    month.bttbReasons?.find(({ product }) => product === productIdentifier)
      ?.reason || null;
  // log(`Extracted ${productIdentifier} BTTB reason from month: ${reason}`);
  return reason;
};

// extract products that are currently the BTTB from a list of products
const getCurrentBTTBProducts = (products) => {
  return products
    .map((product) => ({
      ...product,
      nextBestDate: getNextBestDate(product.fields?.bttbDates),
    }))
    .filter(({ nextBestDate }) => isToday(nextBestDate));
};

// extract date data from a dateList based on a single date object
// `date` timezone could be UTC or the user's
// `dateList` values are likely in UTC
const findDateDataFromDateList = (date, dateList, dateListIsUTC = true) => {
  if (!isValidDate(date) || !dateList?.length) {
    log(
      `findDateDataFromDateList failure for date: ${date}, dateList length: ${dateList?.length}`,
    );
    return;
  }

  // the starting date formatted in UTC
  const comparisonDateStart = formatInTimeZone(
    // if dateList is UTC, convert date to UTC first for a fair comparison
    dateListIsUTC ? zonedTimeToUtc(date, DEFAULT_TIMEZONE) : date,
    DEFAULT_TIMEZONE,
    'yyyy-MM-dd',
  );

  /*
  log(
    `---\nExtracting dateData from dateList for ${date} using comparisonDateStart ${comparisonDateStart}:`,
  );
  */

  // dateList values are in UTC
  const dateData = dateList.find(({ dates }) =>
    dates.some((d) => d.startsWith(comparisonDateStart)),
  );

  /*
  log('DateData and DateList:');
  log(dateData);
  log(dateList);
  */

  return dateData;
};

// pluralize text
const pluralize = (value, singularSuffix, pluralSuffix) => {
  // if pluralSuffix is provided, use it
  if (pluralSuffix) {
    return value === 1 ? singularSuffix : pluralSuffix;
  }

  // else, pluralize manually
  return `${singularSuffix}${value === 1 ? '' : 's'}`;
};

// make something into an oxford comma-delimited list
const commafy = (list = []) => {
  const items = [...list]; // spread prevents mutations

  // items are required
  if (items.length === 0) return;

  // if this is a single item, just return it
  if (items.length === 1) {
    return items[0];
  }

  // if there are only two items, join with "and"
  if (items.length === 2) {
    return items.join(' and ');
  }

  // else, join with oxford commas and an "and"
  const lastItem = items.pop();
  return `${items.join(', ')}, and ${lastItem}`;
};

// create a valid HTML ID
const makeHTMLID = (text) => kebabCase(text).replace(/\W-/g, '');

// format sale day percentage
const formatSaleDayPercentage = (saleDayPercentage) => {
  return saleDayPercentage.toLocaleString('en-us', {
    style: 'percent',
    minimumFractionDigits: 0,
  });
};

export {
  CURRENT_YEAR,
  DEFAULT_TIMEZONE,
  NEWSLETTER_SIGNUP_URL,
  NEXT_YEAR,
  NOW,
  USP_COOKIE_NAME,
  absoluteURL,
  areIs,
  commafy,
  convertToTwoDigits,
  daysInMonth,
  extractBTTBReasonFromMonth,
  extractDates,
  extractMonths,
  findDateDataFromDateList,
  formatSaleDayPercentage,
  getCookie,
  getCurrentBTTBProducts,
  getNextBestDate,
  isDevelopment,
  isProduction,
  makeHTMLID,
  isAmazonURL,
  isBrowser,
  isSSR,
  isTouch,
  isValidEmail,
  log,
  logGTMEvent,
  monetizeAffiliateLink,
  monthName,
  pluralize,
  setCookie,
};
