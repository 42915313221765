import { Script } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import AlgoliaSearchScript from './AlgoliaSearchScript';
import ExitIntentNewsletterSignupDialog from './ExitIntentNewsletterSignupDialog';
import Footer from './Footer';
import MenuBar from './MenuBar';

const PageLayout = ({ children }) => {
  return (
    <>
      <MenuBar />
      {children}
      <Footer />
      <ExitIntentNewsletterSignupDialog />
      <AlgoliaSearchScript />
      <Script
        id="skimlinks-snippet"
        src="https://s.skimresources.com/js/227967X1710232.skimlinks.js"
        strategy="idle"
      />
    </>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
};

PageLayout.defaultProps = {
  children: null,
};

export default PageLayout;
